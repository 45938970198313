import i_o from "../src/images/i_o.svg"
import twitter from "../src/images/twitter.svg"
import discord from "../src/images/discord.svg"
import instagram from "../src/images/instagram.svg"
import magiceden from "../src/images/magiceden.svg"
import burn from "../src/images/burn.svg"


function App() {
  return (
    <div>

<header>
			<nav className="w-screen mx-auto p-12 z-50 fixed inset-x-0 bottom-0">
				<div className="flex items-center">
					<img
						className="h-6 w-6 mx-auto mr-4 cursor-pointer hover:opacity-50 transition duration-500 ease-in-out"
						src={instagram}
						alt="instagram"
						onClick={() => window.open("https://instagram.com/internetobject", "_blank")}
					></img>
          			<img
						className="h-6 w-6 mx-4 cursor-pointer hover:opacity-50 transition duration-500 ease-in-out"
						src={twitter}
						alt="twitter"
						onClick={() => window.open("https://twitter.com/internet_object", "_blank")}
					></img>
					<img
						className="h-6 w-6 mx-4 cursor-pointer hover:opacity-50 transition duration-500 ease-in-out"
						src={discord}
						alt="discord"
						onClick={() => window.open("https://discord.gg/pjyQpVhMRE", "_blank")}
					></img>
					<img
						className="h-7 w-7 mx-4 cursor-pointer hover:opacity-50 transition duration-500 ease-in-out"
						src={magiceden}
						alt="magiceden"
						onClick={() => window.open("https://magiceden.io/marketplace/internet_object_drop_01", "_blank")}
					></img>
					<img
						className="h-6 w-6 ml-4 mx-auto cursor-pointer hover:opacity-50 transition duration-500 ease-in-out"
						src={burn}
						alt="burn"
						onClick={() => window.open("https://burnportal.io/", "_blank")}
					></img>
				</div>
			</nav>
		</header>
      <div className="h-screen bg-blue-600 flex">
        <div className="m-auto">
          <img className="object-center md:pl-8 pl-0 mx-auto md:w-80 w-36 transform hover:rotate-6 duration-300 ease-in-out" src={i_o}></img>
        </div>
      </div>

    </div>
  );
}

export default App;
